/** @jsxImportSource @emotion/react */
import { useHistory } from 'react-router-dom'
import React, { useState, createContext } from 'react'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import ErrorIcon from '@material-ui/icons/Error'
import DoneIcon from '@material-ui/icons/Done'
import { Input } from './Input'
import { List } from './List'
import { mainColor } from '../../plugins/style'
import { sortingProductAPI } from '../../plugins/apis.js'

export const StepContext = createContext()

export const SortingList = () => {
  const history = useHistory()
  const [orderId, setOrderId] = useState(null)
  const [orderIsEdit, setOrderIsEdit] = useState(true)
  const [isEdit, setIsEdit] = useState(true)
  const [step, setStep] = useState(1)
  const [productList, setProductList] = useState([])
  const [bindingProductList, setBindingProductList] = useState([])
  const [modalConfig, setModalConfig] = useState({
    modalStatus: false,
    modalReason: '未知原因',
  })

  // 更新订单成品列表
  const updateProductList = (newProductList, newOrderId, newOrderIsEdit, bind_finished_product) => {
    if (bind_finished_product && bind_finished_product.length) {
      const copyProductList = [...newProductList]

      copyProductList.forEach(item => {
        bind_finished_product.forEach(bindItem => {
          if (+item.meal_id === +bindItem.meal_id) {
            item.bindingNumber = bindItem.num
          }
        })
      })

      setProductList(copyProductList)
      setBindingProductList(bind_finished_product.map(item => ({
        ...item,
        number: +item.num
      })))
      setIsEdit(false)
      setStep(1)
    } else {
      setProductList(newProductList)
      setBindingProductList([])
      setIsEdit(true)
    }

    setOrderId(newOrderId)
    setOrderIsEdit(newOrderIsEdit)
  }

  const updateProductBindingNumber = (array, id) => {
    const copyProductList = [...productList]
    const findIndex = copyProductList.findIndex(item => item.meal_id === id)
    const filterBindingProductList = array.filter(item => item.meal_id === id)

    copyProductList[findIndex].bindingNumber = filterBindingProductList.reduce((a, b) => a + b.number, 0)

    setProductList(copyProductList)
  }

  // 更新绑定成品列表
  const updateBindingProductList = (data) => {
    updateProductBindingNumber([...bindingProductList, data], data.meal_id)

    const findIndex = bindingProductList.findIndex(item => item.batch_id === data.batch_id)

    if (findIndex !== -1) {
      bindingProductList[findIndex].number = bindingProductList[findIndex].number + data.number

      setBindingProductList([...bindingProductList])
    } else {
      setBindingProductList([data, ...bindingProductList])
    }
  }

  // 更新选定的产品数量
  const updateSelectBindingProductNumber = ({ number, index }) => {
    const copyBindingProductList = [...bindingProductList]
    copyBindingProductList[index].number = +number

    updateProductBindingNumber(copyBindingProductList, copyBindingProductList[index].meal_id)
    setBindingProductList(copyBindingProductList)
  }

  // 删除选定的产品
  const deleteSelectBindingProduct = (i) => {
    updateProductBindingNumber(bindingProductList.filter((item, index) => index !== i), bindingProductList[i].meal_id)
    setBindingProductList(bindingProductList.filter((item, index) => index !== i))
  }

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Modal
        open={modalConfig.modalStatus}
        onClose={() => setModalConfig({ ...modalConfig, modalStatus: false })}
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fff',
            width: '300px',
            height: '300px',
            borderRadius: '10px',
            padding: '20px',
          }}
        >
          { modalConfig.modalReason === '操作成功' ? <DoneIcon css={{ color: '#5da331' }} style={{ fontSize: 100 }} /> : <ErrorIcon color="secondary" style={{ fontSize: 100 }} /> }

          <h2
            css={{
              marginBottom: '40px',
            }}
          >
            {modalConfig.modalReason}
          </h2>
          <Button
            css={{
              width: '100%',
            }}
            variant="contained"
            color={ modalConfig.modalReason === '操作成功' ? 'primary' : 'secondary' }
            disableElevation
            onClick={() =>
              setModalConfig({ ...modalConfig, modalStatus: false })
            }
          >
            确定
          </Button>
        </div>
      </Modal>

      <Button
        css={{
          color: '#101010',
          borderColor: '#101010',
          position: 'absolute',
          top: '8px',
          left: '8px',
        }}
        onClick={() => {
          history.go(-1)
        }}
        variant="outlined"
        color="primary"
      >
        返回
      </Button>

      <h1
        css={{
          marginTop: '64px',
        }}
      >
        订单分拣成品
      </h1>

        <StepContext.Provider value={{ isEdit, step, setStep }}>
          <Input updateProductList={ updateProductList }
            updateBindingProductList={updateBindingProductList}
            bindingProductList={bindingProductList}
          />
        </StepContext.Provider>


      <List isEdit={ isEdit }
        changeEdit={() => {
          setIsEdit(true)
          setStep(2)
        }}
        orderIsEdit={ orderIsEdit }
        productList={ productList }
        bindingProductList={bindingProductList}
        updateSelectBindingProductNumber={updateSelectBindingProductNumber}
        deleteSelectBindingProduct={deleteSelectBindingProduct}
      />

      <Button
        css={{
          width: '300px',
          marginTop: '50px',
          backgroundColor: mainColor,
          '&:hover': {
            backgroundColor: mainColor
          }
        }}
        variant="contained"
        color="primary"
        onClick={() => {
          const findIndex = productList.findIndex(item => +item.bindingNumber !== +item.num)

          if (findIndex !== -1) {
            setModalConfig({
              modalReason: '绑定数量与所需数量不匹配',
              modalStatus: true
            })

            return
          }

          if (!orderIsEdit) {
            setModalConfig({
              modalReason: '当前订单不支持编辑',
              modalStatus: true,
            })

            return
          }

          sortingProductAPI({
            step: 3,
            code: '1',
            order_id: orderId,
            bind_data: bindingProductList.map(item => ({
              batch_id: item.batch_id,
              num: item.number
            }))
          }).then(({ code, message }) => {
            if (code === 0) {
              setModalConfig({
                modalReason: '操作成功',
                modalStatus: true
              })

              setStep(1)

              setIsEdit(false)
            } else {
              setModalConfig({
                modalReason: message,
                modalStatus: true
              })
            }
          })
        }}
      >
        绑定
      </Button>
    </div>
  )
}
