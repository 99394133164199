/** @jsxImportSource @emotion/react */
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

export const List = ({ isEdit, orderIsEdit, productList, bindingProductList, updateSelectBindingProductNumber, deleteSelectBindingProduct, changeEdit }) => {
  return (
    <div css={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: '32px',
    }}>
      <p css={{
        padding: '0 16px',
        margin: 0
      }}>订单包含成品</p>
      <ul
        css={{
          width: '100%',
          maxHeight: '200px',
          overflow: 'auto',
          li: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '3px 16px',
            fontSize: '12px',
          },
        }}
      >
        {productList.map((item, index) => (
          <li key={index}>
            <span
              css={{
                width: '120px',
              }}
            >
              {item.meal_no}
            </span>
            <span
              css={{
                flex: '1',
                margin: '0 5px',
                textAlign: 'left'
              }}
            >
              {item.name}
            </span>
            <span
              css={{
                width: '50px',
                textAlign: 'center',
                marginRight: '5px',
              }}
            >
              X{item.num}
            </span>
            <span
              css={{
                position: 'absolute',
                right: '20px',
                top: '3px',
                color: 'green'
              }}
            >
              { +item.bindingNumber === +item.num && isEdit ? <CheckOutlinedIcon style={{ fontSize: 16 }} /> : null }
            </span>
          </li>
        ))}
      </ul>

      <div css={{
        display: 'flex',
        flexDirection: 'column',
        margin: '20px',
        border: '1px solid #333',
        padding: '15px'
      }}>

        <div css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '10px'
        }}>
          <p css={{
            margin: 0
          }}>绑定的成品</p>

          { !isEdit && orderIsEdit ? <Button css={{
            width: '60px',
            height: '30px'
          }} color="primary" variant="contained" size="small" onClick={() => changeEdit(true)}>编辑</Button> : null }
        </div>

        <ul
          css={{
            width: '100%',
            height: '300px',
            overflow: 'auto',
            li: {
              position: 'relative',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontSize: '12px',
              marginBottom: '10px',
              lineHeight: '20px'
            },
          }}
        >
          {bindingProductList.map((item, index) => (
            <li key={index}>
              <span
                css={{
                  width: '120px',
                }}
              >
                {item.batch_no}
              </span>
              <span
                css={{
                  flex: '1',
                  margin: '0 5px',
                  textAlign: 'left'
                }}
              >
                {item.name}
              </span>
              <span
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '50px',
                  textAlign: 'center',
                  marginRight: '30px'
                }}
              >
                X
                { isEdit ?
                <TextField
                  css={{
                    alignItems: 'center',
                    width: '50px',
                    height: '16px',
                    marginLeft: '5px',
                    input: {
                      padding: '1px 0',
                      textAlign: 'center',
                      fontSize: 12,
                      lineHeight: '16px'
                    }
                  }}
                  variant="outlined"
                  type="text"
                  value={item.number}
                  onChange={(event) => {
                    updateSelectBindingProductNumber({
                      index,
                      number: event.target.value
                    })
                  }}
                /> : item.number }
              </span>
              { isEdit ? <span
                css={{
                  position: 'absolute',
                  right: '5px',
                  top: '0px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'black'
                }}
                onClick={() => deleteSelectBindingProduct(index)}
              >
                <DeleteOutlinedIcon style={{ fontSize: 20 }}/>
              </span> : null }
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
