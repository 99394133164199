/** @jsxImportSource @emotion/react */
import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import Snackbar from '@material-ui/core/Snackbar'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import ErrorIcon from '@material-ui/icons/Error'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import { sortingProductAPI } from '../../plugins/apis.js'
import { StepContext } from './Index'

export const Input = ({ updateProductList, updateBindingProductList, bindingProductList }) => {
  const history = useHistory()

  const { step, setStep, isEdit } = useContext(StepContext)
  const [inputValue, setInputValue] = useState('')
  const [orderNumber, setOrderNumber] = useState('')
  const [orderId, setOrderId] = useState('')
  const [confirmModalStatus, setConfirmModalStatus] = useState(false)
  const [modalConfig, setModalConfig] = useState({
    modalStatus: false,
    modalReason: '未知原因',
  })
  const [toastConfig, setToastConfig] = useState({
    toastStatus: false,
    toastMessage: '登陆失败',
  })

  const updateData = (updateStep) => {
    sortingProductAPI({
      step: updateStep,
      code: inputValue,
      order_id: updateStep === 2 || updateStep === 3 ? orderId : ''
     }).then(({ code, message, msg, data }) => {
      setInputValue('')

       if (code === -2) {
         setToastConfig({ toastStatus: true, toastMessage: msg })

         setTimeout(() => {
           history.replace('/')
          }, 1500)

          return
        }

        if (code !== 0) {
          setModalConfig({
            modalReason: message || msg,
            modalStatus: true,
          })

          return
        }

        const { order_id, order_no, is_edit, finished_product, bind_finished_product, finished_batch_info } = data

        if (updateStep === 1) {
          setStep(2)

          setOrderNumber(order_no)
          setOrderId(order_id)
          updateProductList(finished_product, order_id, is_edit, bind_finished_product)
        } else if (updateStep === 2) {
          updateBindingProductList({
            ...finished_batch_info,
            number: 1
          })
        }
      })
  }

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => {
          setToastConfig({ ...toastConfig, toastStatus: false })
        }}
        open={toastConfig.toastStatus}
        autoHideDuration={1500}
        message={toastConfig.toastMessage}
      />

      <Modal
        open={modalConfig.modalStatus}
        onClose={() => setModalConfig({ ...modalConfig, modalStatus: false })}
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fff',
            width: '300px',
            height: '300px',
            borderRadius: '10px',
            padding: '20px',
          }}
        >
          <ErrorIcon color="secondary" style={{ fontSize: 100 }} />
          <h2
            css={{
              marginBottom: '40px',
              textAlign: 'center'
            }}
          >
            {modalConfig.modalReason}
          </h2>
          <Button
            css={{
              width: '100%',
            }}
            variant="contained"
            color="secondary"
            disableElevation
            onClick={() =>
              setModalConfig({ ...modalConfig, modalStatus: false })
            }
          >
            确定
          </Button>
        </div>
      </Modal>

      <Dialog
        open={confirmModalStatus}
        aria-labelledby="form-dialog-title"
        onClose={() => {
          setInputValue('')
          setConfirmModalStatus(false)
        }}
      >
        <DialogTitle id="form-dialog-title">提示</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            绑定信息尚未保存，确认切换订单么？
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setInputValue('')
              setConfirmModalStatus(false)
            }}
            color="primary"
          >
            再想想
          </Button>
          <Button
            onClick={() => {
              updateData(1)
              setConfirmModalStatus(false)
            }}
            color="primary"
          >
            确认
          </Button>
        </DialogActions>
      </Dialog>

      <TextField
        css={{
          width: '280px',
        }}
        variant="outlined"
        label="请扫订单号、物流单号、成品批次号"
        type="text"
        value={inputValue}
        onChange={(event) => {
          setInputValue(event.target.value)
        }}
        autoFocus
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            if (!inputValue) return

            const toLowerCaseValue = inputValue.toLowerCase()

            // 当前号码不是订单号、运单号、成品批次号中的任何一个
            if (!toLowerCaseValue.includes('yjb') && !toLowerCaseValue.includes('xjd') && !toLowerCaseValue.includes('sf')) {
              setModalConfig({
                modalReason: '错误的号码',
                modalStatus: true
              })

              setInputValue('')
              return
            }

            // 单号包含 xjd 或 sf 时，默认为橘袋订单号或物流号，此时切换新订单
            if (isEdit && orderId && (toLowerCaseValue.includes('xjd') || toLowerCaseValue.includes('sf'))) {
              if (bindingProductList.length) {
                setConfirmModalStatus(true)
              } else {
                updateData(1)
              }

              return
            }

            // 当前未点击编辑，用户扫码了成品批次号时如此提示
            if (!isEdit && toLowerCaseValue.includes('yjb')) {
              setModalConfig({
                modalReason: '点击编辑后可修改绑定的成品',
                modalStatus: true
              })

              setInputValue('')
              return
            }

            updateData(step)
          }
        }}
      />

      <p css={{
        color: '#1a1a1a',
        margin: '20px auto'
      }}>
        { orderNumber }
      </p>

    </React.Fragment>
  )
}
